/* Init */
@import 'functions/var';
@import 'functions/responsive';
@import 'reset';

/* Elements */
@import 'elements/header';
@import 'elements/footer';


/* Pages */
body {
	
}

#main {

}

/* home */
.home-banner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	.banner-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: bottom 38px center;
		background-size: cover;
		.video-js {
			position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate3d(-50%, -50%, 0);
		    opacity: 0;
		    &.vjs-playing {
		    	opacity: 1;
		    }
		}
	}
	.nav-item {
		position: absolute;
		right: 5%;
		top: 50%;
		transform: translateY(-50%);
		li {
			text-align: right;
			color: #fff;
			font-size: 15px;
			margin-bottom: 10px;
			cursor: pointer;
			&:last-child {
				margin-bottom: 0px;
			}
			span {
				margin-right: 7px;
			}
			i {
				font-size: 20px;
			}
			&.active, &:hover {
				color: #eb8923;
			}
		}
	}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* page */
.banner {
	height: 415px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #fff;
	padding-top: 135px;
	h3 {
		font-size: 14px;
		font-weight: bold;
		text-shadow: 5px 5px 10px rgb(0,0,0);
	}
	h2 {
		font-size: 26px;
		font-weight: bold;
		text-shadow: 5px 5px 10px rgb(0,0,0);
	}
}

.input {
	height: 40px;
	background: $black;
	color: #fff;
	font-size: 12px;
	font-weight: 500;
	padding: 0 15px;
	border: 1px solid rgba(255,255,255,0.5);
	width: 100%;
	outline: none;
	@include placeholder {
		color: #fff;
	}
}
.input2 {
	height: 58px;
	background: #ebebeb;
	color: #000;
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	border: 1px solid rgba(255,255,255,0.5);
	width: 100%;
	border-radius: 10px;
	outline: none;
	@include placeholder {
		color: #959595;
	}
}
textarea.input2 {
	resize: none;
	height: 264px;
}
.submit {
	text-align: center;
}


.section {
	
}
.s-title {
	margin-top: 90px;
	margin-bottom: 30px;
	font-size: 26px;
	font-weight: 500;
	color: #000000;
	text-align: center;
	padding: 10px 35px;
	position: relative;
	display: inline-block;
	min-width: 310px;
	@include xs {
		min-width: 290px;
	}
	&::before, &::after {
		content: '';
		width: 90px;
		height: 2px;
		background: $red;
		position: absolute;
	}
	&::before {
		top: 0;
		left: 0;
	}
	&::after {
		bottom: 0;
		right: 0;
	}
	>span {
		display: block;
		position: absolute;
		bottom: -5px;
		left: 0;
		width: 100%;
		font-size: 11px;
		color: #959595;
		font-weight: 500;
	}
	&.no-line {
		padding-left: 0;
		padding-right: 0;
		&::before, &::after {
			display: none;
		}
	}
	@include xs {
		font-size: 18px;
		padding: 10px 25px;
		margin-top: 30px;
		&::before, &::after {
			width: 70px;
		}
	}
}
.s-desc {
	margin: 0px;
}
.text-clicklink {
	font-size: 10px;
	color: $red;
}

.map-choose {
	position: relative;
	margin-top: 90px;
	@include xs {
		margin-top: 30px;
	}
	> img {width: 100%;}
	.point {
		position: absolute;
		transform: translate3d(-50%, -50%, 0);
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: $red;
		cursor: pointer;
		transition: all ease 0.2s;
		&.square {
			border-radius: 0px;
		}
		&:hover {
			transform: translate3d(-50%, -50%, 0) scale(1.2);
		}
		.title {
			padding: 0px 0px 0px 15px;
    		font-weight: 600;
    		position: relative;
		}
	}
}

.link2-group {
	margin: 30px 0;
}
.link2 {
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	padding: 7px 25px;
	border-radius: 15px;
	background: linear-gradient(-90deg, #4c0701, $red);
	transition: all ease 0.2s;
	margin-bottom: 10px;
	&:hover {
		box-shadow: 0 3px 10px rgba(0,0,0,0.2);
		color: #fff;
	}
	i {
		margin-right: 10px;
	}
}

.des-img-list {
	.item {
		padding-bottom: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}

.d-gallery {
	margin-top: 25px;
	margin-bottom: 100px;
	overflow: hidden;
	@include xs {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.item {
		position: relative;
		width: 25%;
		float: left;
		background: #000;
		overflow: hidden;
		@include xs {
			width: 50% !important;
		}
		.img {
			padding-bottom: 100%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			transition: all ease-out 0.2s;
			@include xs {
				padding-bottom: 100% !important;
			}
		}
		.title {
			font-size: 16px;
			font-weight: bold;
			color: #fff;
			text-align: center;
			text-shadow: 2px 2px 5px #000;
			padding: 0 25px;
		}
		.desc {
			font-size: 14px;
			font-weight: 500;
			color: #fff;
			text-align: justify;
			padding: 0 25px;
			@include lg {
				font-size: 12px;
				line-height: 16px;
			}
		}
		a {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: none;
			@include md2down {
				display: block;
			}
		}
		.layer1 {
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 100%;
			transform: translateY(-20px);
			transition: all ease 0.2s;
		}
		.layer2 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			visibility: hidden;
			opacity: 0;
			transform: translateY(-20px);
			transition: all ease 0.2s;
		}
		&.item1 {
			width: 25%;
			.img {
				padding-bottom: 200%;
			}
		}
		&.item2 {
			width: 25%;
		}
		&.item3 {
			width: 50%;
			.img {
				padding-bottom: 50%;
			}
		}
		&.item4 {
			width: 25%;
		}
		&:hover {
			.img {
				opacity: 0.5;
				transform: scale(1.2);
				transition: all ease-in 0.5s;
			}
			.layer1 {
				visibility: hidden;
				opacity: 0;
				transform: translateY(0px);
				transition: all ease 0.2s;
			}
			.layer2 {
				@include lg2up {
					visibility: visible;
					opacity: 1;
					transform: translateY(0px);
					transition: all ease 0.2s 0.1s;
				}
			}
		}
	}
}

.des2-container {
	max-width: 820px;
	margin: 0 auto;
	padding-top: 50px;
	padding-bottom: 100px;
	@include sm2down {
		padding-top: 0;
		padding-bottom: 50px;
	}
}
.des2-block {
	.title {
		font-size: 26px;
		font-weight: 500;
		border-bottom: 0.5px solid #e3e3e3;
		padding-bottom: 5px;
		margin-top: 50px;
		strong {
			font-weight: 700;
		}
	}
	p {
		strong {
			font-weight: 600;
		}
	}
	.img-wrap {
		position: relative;
		margin-top: 50px;
		margin-bottom: 40px;
		@include sm2down {
			margin-top: 10px;
		}
		.img1, .img2 {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 10px;
			box-shadow: 10px 10px 20px rgba(0,0,0,0.2);
		}
		.img1 {
			width: 90%;
			padding-bottom: 90%;
			position: relative;
		}
		.img2 {
			width: 50%;
			padding-bottom: 60%;
			position: absolute;
			right: 0;
			bottom: -40px;
		}
	}
}

.styles-container {
	max-width: 1000px;
	margin: 0 auto;
	padding-bottom: 100px;
	@include sm2down {
		padding-bottom: 30px;
	}
}
.style-block {
	.title {
		font-size: 26px;
		font-weight: 500;
		border-bottom: 0.5px solid #e3e3e3;
		padding-bottom: 5px;
		margin-top: 50px;
		@include xs {
			margin-top: 30px;
		}
		strong {
			font-weight: 700;
		}
	}
	p {
		text-align: justify;
		strong {
			font-weight: 600;
		}
	}
	.img-wrap {
		position: relative;
		margin-top: 50px;
		margin-bottom: 20px;
		margin-left: 80px;
		@include xs {
			margin-top: 10px;
			margin-bottom: 0px;
		}
		.img1, .img2 {
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			border-radius: 10px;
			box-shadow: 10px 10px 20px rgba(0,0,0,0.2);
		}
		.img2 {
			width: 100%;
			padding-bottom: 72.99%;
			position: relative;
		}
		.img1 {
			width: 50%;
		    padding-bottom: 60%;
		    position: absolute;
		    left: -80px;
		    top: 50%;
		    z-index: 1;
		    transform: translateY(-50%);
		    margin-top: 5px;
		}
	}
	.link2 {
		min-width: 145px;
	}
}
.styles-filter {
	border-bottom: 1px solid #dddddd;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 20px;
	@include xs {
		border: none;
		margin-top: 30px;
		margin-bottom: 0px;
	}
	button {
		background: #fff;
		border: none;
		font-weight: bold;
		padding: 5px 12px;
		border-left: 1px solid #dddddd;
		cursor: pointer;
		@include xs {
			border: none;
			padding: 5px;
		}
		&:first-child {
			border: none;
		}
		&.mixitup-control-active {
			color: $red;
		}
	}
}

.res-container {
	max-width: 860px;
	margin: 0 auto;
	padding-bottom: 70px;
	@include xs {
		padding-bottom: 30px;
	}
}
.res-block {
	font-weight: 500;
	color: #000;
	margin-top: 50px;
	line-height: 18px;
	@include xs {
		margin-top: 30px;
	}
	.title {
		font-size: 16px;
		font-weight: bold;
		position: relative;
		margin-bottom: 15px;
		&::after {
			content: '';
			position: absolute;
			height: 2px;
			background: $red;
			width: 54px;
			bottom: 4px;
    		margin-left: 4px;
		}
	}
	p {
		margin: 0px;
		text-align: justify;
	}
}

.section-gallery {
	background: #ebebeb;
	.s-title {
		margin-top: 70px;
		@include xs {
			margin-top: 30px;
		}
	}
}

.section-adventage {
	background: #ebebeb;
	padding-bottom: 100px;
	@include xs {
		padding-bottom: 30px;
	}
	.s-title {
		margin-top: 50px;
		@include xs {
			margin-top: 30px;
		}
	}
}
.about-container {
	max-width: 860px;
	margin: 0 auto;
	padding-bottom: 70px;
	@include xs {
		padding-bottom: 30px;
	}
}
.adventages-container {
	max-width: 745px;
	margin: 0 auto;
	> .row {
		margin-left: -4px;
		margin-right: -4px;
		> div {
			padding-left: 4px;
			padding-right: 4px;
			margin-bottom: 8px;
		}
	}
}
.adventage-block {
	background: #fff;
	padding-bottom: 100%;
	position: relative;
	overflow: hidden;
	.content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 15px;
		.icon-check {
			margin-bottom: 25px;
			transform: translateY(10px);
			@include sm2down {
				width: 50px;
			}
		}
		.title {
			color: #2e2e2e;
			font-weight: bold;
			font-size: 15px;
			margin: 0;
			transform: translateY(10px);
			@include sm2down {
				font-size: 12px;
			}
		}
	}
	&:hover {
		background: linear-gradient(-90deg, #4c0701, $red);
		box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
		transition: all ease 0.2s;
		.icon-check {
			opacity: 0;
			visibility: hidden;
			transition: all ease 0.2s;
		}
		.title {
			color: #fff;
			transform: translateY(0);
			transition: all ease 0.2s;
		}
	}
}

.expertise-container {
	margin-top: 50px;
	margin-bottom: 100px;
	@include xs {
		margin-top: 20px;
		margin-bottom: 20px;
	}
	> .row {
		margin-left: -7px;
		margin-right: -7px;
		> div {
			padding-left: 7px;
			padding-right: 7px;
			margin-bottom: 14px;
		}
	}
}
.expertise-block {
	position: relative;
	overflow: hidden;
	border: 1px solid #ccc;
	@include lg2down {
		font-size: 12px;
	}
	.img {
		padding-bottom: 107%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.chucvu {
		margin: 0px 0px 5px;
	}
	.name {
		font-weight: bold;
		font-size: 15px;
		margin: 0 0 5px;
	}
	.mota {
		margin: 0;
		line-height: 1.3;
	}
	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		font-weight: 500;
		padding: 20px 10px;
		background: rgba(0,0,0,0.8);
		color: #fff;
		opacity: 0;
		visibility: hidden;
		transform: translateY(50%);
		transition: all ease-out 0.1s;
	}
	&:hover {
		.content {
			opacity: 1;
			visibility: visible;
			transform: translateY(0%);
			transition: all ease-in 0.2s;
		}
	}
}

.contact-container {
	padding-bottom: 50px;
	@include sm2down {
		padding-bottom: 10px;
	}
	>.row {
		margin-left: -6px;
		margin-right: -6px;
		> div {
			padding-left: 6px;
			padding-right: 6px;
			margin-bottom: 12px;
		}
	}
	.submit2 {
		width: 300px;
		max-width: 100%;
	}
}
.submit2 {
	display: inline-block;
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	padding: 14px 25px;
	border-radius: 10px;
	background: linear-gradient(-90deg, #4c0701, $red);
	transition: all ease 0.2s;
	margin-bottom: 10px;
	cursor: pointer;
	border: none;
	&:hover {
		box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
		color: #fff;
	}
	i {
		margin-right: 10px;
	}
}

.contact-map {
	height: 457px;
	position: relative;
	.map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}

.section-achievements {
	.img {
		padding-bottom: 119%;
		background: #2e2e2e;
		position: relative;
	}
	.img2 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: no-repeat center;
		background-size: cover;
	}
}
.achievements-container {
	max-width: 860px;
	margin: 0 auto;
	padding-bottom: 60px;
	@include xs {
		padding-bottom: 30px;
	}
	.s-title {
		margin-top: 0px;
		@include xs {
			margin-bottom: 0px;
		}
	}
}

.achievement-block {
	border-left: 0.5px solid #ebebeb;
	position: relative;
	padding-top: 60px;
	@include xs {
		margin-top: 10px;
		padding-top: 30px;
	}
	.swiper-button-prev,.swiper-button-next {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #b7b7b7;
		position: absolute;
		left: -10px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		margin: 0;
		&.swiper-button-disabled {
			opacity: 1;
		}
	}
	.swiper-button-prev {
		top: 0;
	}
	.swiper-button-next {
		bottom: 0;
		top: auto;
	}
}
.achievement-item {
	display: flex;
	cursor: pointer;
	.date {
		font-size: 12px;
		color: $red;
		font-weight: 500;
		border-bottom: 0.5px solid #ebebeb;
		padding: 0px 10px 2px;
		display: inline-block;
		margin-right: 15px;
		min-width: 110px;
		text-align: center;
		white-space: nowrap;
		@include xs {
			min-width: auto;
		}
	}
	.title {
		font-size: 15px;
		font-weight: bold;
		@include xs {
			margin-bottom: 5px;
			font-size: 13px;
		}
	}
	.desc {
		font-weight: 500;
		@include xs {
			font-size: 12px;
		}
	}
}

.swiper-achievement {
	width: 100%;
	height: 350px;
	.swiper-pagination {
		display: none;
	}
}

.agent-container {
	max-width: 970px;
	margin: 0 auto;
	padding-bottom: 100px;
	@include lg2down {
		padding-bottom: 50px;
	}
	@include sm2down {
		padding-bottom: 30px;
	}
	@include xs {
		padding-bottom: 0;
	}
	.text1 {
		text-align: center;
		font-weight: bold;
		margin-top: 50px;
		margin-bottom: 15px;
		@include sm2down {
			margin-top: 30px;
			margin-bottom: 0px;
		}
	}
	.text2 {
		text-align: center;
		font-weight: bold;
		font-size: 26px;
		margin: 0 0 15px;
	}
	.text3 {
		text-align: center;
		font-weight: 500;
		margin-bottom: 70px;
		@include sm2down {
			margin-bottom: 30px;
		}
	}
}
.agent-block {
	border-radius: 20px;
	box-shadow: 5px 5px 20px rgba(0,0,0,0.5);
	overflow: hidden;
	line-height: 1.2;
	position: relative;
	z-index: 1;
	margin-bottom: 40px;
	.img {
		padding: 0 0 74.44% 0;
		background: no-repeat center;
		background-size: cover;
	}
	.content {
		padding: 10px 30px 20px;
		position: relative;
		color: $black;
	}
	.title {
		font-size: 17px;
		font-weight: bold;
	}
	.date {
		font-size: 10px;
		margin-bottom: 5px;
		i.fa-dollar-sign {
			width: 15px;
			height: 15px;
			border-radius: 50%;
			display: inline-block;
			border: 1px solid $black;
			text-align: center;
			padding: 2px 0;
			font-size: 8px;
			margin-right: 5px;
		}
		i.fa-calendar-alt {
			font-size: 14px;
			margin-right: 5px;
			margin-left: 10px;
		}
	}
	.desc1 {
		font-size: 12px;
		position: relative;
		padding-left: 18px;
		border-bottom: 0.5px solid #f1f1f1;
		padding-bottom: 10px;
		margin-bottom: 10px;
		>i.fa {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			margin-top: -5px;
		}
	}
	.desc2 {
		font-weight: 12px;
		font-weight: 500;
	}
	.total-step {
		width: 53px;
		height: 53px;
		background-color: $red;
		border-radius: 10px;
		color: #fff;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		padding: 8px 0;
		position: absolute;
		right: 20px;
		top: -30px;
		span {
			display: block;
			font-size: 8px;
			font-weight: 500;
		}
	}
}
.agent-block-bottom {
	position: absolute;
	bottom: 0px;
	left: 15px;
	right: 15px;
	padding: 0 20px;
	z-index: 0;
	@include md {
		left: 5px;
		right: 5px;
		padding: 0;
	}
	.btn-link, .btn-link2 {
		font-size: 13px;
		font-weight: 600;
		text-align: center;
		padding: 10px 10px;
		text-transform: uppercase;
		color: #fff;
		background: $red;
		border-radius: 0 0 10px 10px;
		height: 40px;
		display: block;
		margin: 0 2px;
		@include md {
			font-size: 10px;
			height: 35px;
			border-radius: 20px;
		}
		@include xs {
			font-size: 11px;
			padding: 13px 10px;
		}
		&:hover {
			text-decoration: none;
		}
	}
	.btn-link2 {
		background: #ccc;
	}
}
.row.agent-list {
	margin-left: -7px;
	margin-right: -7px;
	> div {
		padding-left: 7px;
		padding-right: 7px;
		@include sm2down {
			margin-bottom: 30px;
		}
	}
}

.agent-block2 {
	width: 100%;
	border-radius: 0px;
	line-height: 1.2;
	position: relative;
	z-index: 1;
	margin-bottom: 0px;
	@include xs {
		width: 100%;
	}
	.img {
		width: 100%;
		padding-bottom: 51.44%;
		border: 4px solid $red;
		background-position: center;
		background-repeat: no-repeat;
		background-size: auto 80%;
		&.img2 {
			padding-bottom: 100%;
		}
	}
	.content {
		padding: 10px 30px 0px;
		position: relative;
		color: $black;
		@include md2down {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
	.title {
		font-size: 17px;
		font-weight: bold;
		text-transform: uppercase;
		border-bottom: 1px solid #d5d5d5;
		padding-bottom: 5px;
	}
	.desc {
		margin-bottom: 26px;
		text-align: justify;
	}
	.link {
		background: $red;
		background: linear-gradient(to right, $red, #4c0701);
		color: #fff;
		text-align: center;
		border-radius: 15px;
		padding: 7px 15px;
		font-weight: bold;
		display: inline-block;
		font-size: 14px;
		transition: all ease 0.2s;
		&:hover {
			box-shadow: 0 3px 10px rgba(0,0,0,0.2);
		}
	}
}

.agent-block3 {
	position: relative;
	overflow: hidden;
	.img {
		padding-bottom: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: all ease-out 0.2s;
	}
	.layer {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.layer1 {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		padding-top: 20%;
		background: rgba(0,0,0,0.5);
		.title {
			font-size: 22px;
			font-weight: bold;
			text-align: center;
			@include md2down {
				font-size: 18px;
			}
			@include sm2down {
				font-size: 14px;
			}
		}
	}
	.layer2 {
		background: $red;
		background: linear-gradient(to right, $red, #4c0701);
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		visibility: hidden;
		transition: all ease-out 0.2s;
	}
	.link {
		color: #fff;
		text-align: center;
		border-radius: 15px;
		padding: 7px 15px;
		font-weight: bold;
		display: inline-block;
		font-size: 14px;
		transition: all ease 0.2s;
		border: 1px solid #fff;
		&:hover {
			box-shadow: 0 3px 10px rgba(0,0,0,0.2);
		}
	}
	&:hover, &.active {
		.img {
			transform: scale(1.2);
			transition: all ease-in 0.3s;
		}
		.layer2 {
			opacity: 1;
			visibility: visible;
			transition: all ease-in 0.3s;
		}
	}
}
.agent7-container {
	max-width: 790px;
	margin: 0 auto;
	padding: 90px 0 70px;
	@include md2down {
		padding: 50px 0 30px;
	}
	@include xs {
		padding-top: 30px;
		padding-bottom: 10px;
	}
}
.row.agent7-list {
	margin-left: -10px;
	margin-right: -10px;
	>div {
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 20px;
	}
}


.row.agent5-list {
	margin-left: -1px;
	margin-right: -1px;
	>div {
		padding-left: 1px;
		padding-right: 1px;
		margin-bottom: 2px;
	}
}
.agent-block5 {
	position: relative;
	overflow: hidden;
	.img {
		padding-bottom: 143.75%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		transition: all ease-out 0.2s;
	}
	.layer {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.layer1 {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		background: rgba(0,0,0,0.5);
		transition: all ease-out 0.2s;
		&::before {
			content: '';
			position: absolute;
			top: 0px;
			right: 0px;
			bottom: 0px;
			left: 0px;
			border: 1px solid #fff;
			opacity: 0;
			transition: all ease-out 0.2s;
		}
		.title {
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			@include md2down {
				font-size: 16px;
			}
			@include sm2down {
				font-size: 14px;
			}
		}
	}
	&:hover {
		.img {
			transition: all ease-in 0.3s;
			transform: scale(1.2);
		}
		.layer1 {
			transition: all ease-in 0.3s;
			background: none;
			&::before {
				opacity: 1;
				top: 10px;
				right: 10px;
				bottom: 10px;
				left: 10px;
				transition: all ease-in 0.3s;
			}
		}
	}
}

.agent4-container {
	max-width: 100%;
	margin: 0 auto;
	padding-bottom: 30px;
	@include md2down {
	}
}

.style-block-agent {
	.title {
		border-bottom: none;
	}
	.discount {
		color: $red;
		font-size: 17px;
		font-weight: 600;
		border-bottom: 0.5px solid #e3e3e3;
		display: inline-block;
		padding-bottom: 10px;
		margin-bottom: 20px;
		min-width: 70%;
	}
	table {
		td:first-child {
			text-align: center;
			padding: 3px 5px 3px 0;
		}
	}
	.img-wrap {
		margin-left: 22%;
		margin-top: 20px;
		.img1 {
			width: 40%;
			padding-bottom: 40%;
			border-radius: 0px;
			left: -28%;
			margin-top: 0px;
		}
		.img2 {
			padding-bottom: 54.32%;
			border-radius: 0px;
		}
	}
	.link2 {
		margin-top: 15px;
	}
}
.agent-time-block {
	display: inline-block;
	margin-top: 50px;
	@include xs {
		margin-top: 30px;
	}
	p {
		font-size: 10px;
		color: #959595;
		padding-left: 10px;
		margin-bottom: 8px;
		text-align: left;
		a {
			color: #959595;
			font-size: 14px;
			margin-left: 5px;
			&:hover {
				color: $red;
			}
		}
	}
	.date-time {
		display: flex;
		> div {
			border: 1px solid #959595;
			margin-right: 8px;
			border-radius: 8px;
			min-width: 40px;
			text-align: center;
			color: #959595;
			padding: 4px 0;
			>div:first-child {
				font-size: 20px;
				line-height: 1;
			}
			>div:last-child {
				font-size: 9px;
			}
		}
	}
}

.agent-modal {
	.modal-lg {
		max-width: 660px;
	}
	.modal-content {
		background: #ebebeb;
		.modal-header {
			padding-top: 20px;
			padding-bottom: 0;
			padding-left: 50px;
			padding-right: 50px;
			@include sm2down {
				padding-top: 10px;
				padding-left: 20px;
				padding-right: 20px;
			}
			.modal-title {
				font-size: 23px;
				color: $red;
				font-weight: 500;
				line-height: 1.2;
    			margin-bottom: 10px;
			}
		}
		.link2 {
			margin: 0 0 30px;
			@include sm2down {
				margin-bottom: 20px;
			}
		}
	}
	.modal-body {
		padding-top: 0;
		padding-bottom: 0;
		padding-left: 50px;
		padding-right: 50px;
		@include sm2down {
			padding-left: 20px;
			padding-right: 20px;
			.row {
				margin-left: -5px;
				margin-right: -5px;
				>div {
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
		.desc {
			font-size: 12px;
			line-height: 1.2;
		}
		label {
			margin-bottom: 5px;
			font-weight: 500;
		}
		.form-input {
			width: 100%;
			border-radius: 5px;
			border: 1px solid #808080;
			background: none;
			padding: 6px 15px;
			margin-bottom: 20px;
			height: 35px;
			outline: none;
		}
		textarea.form-input {height: 130px; resize: none;}
		.select-wrap {
			position: relative;
			div {
				position: absolute;
			    top: 0;
			    right: 0;
			    height: 35px;
			    border: 1px solid #808080;
			    border-left-color: transparent;
			    border-radius: 0 5px 5px 0;
			    background: #ebebeb;
			    display: flex;
			    justify-content: center;
			    align-items: center;
			    padding-right: 5px;
			    color: #808080;
			}
		}
	}
}


/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}