* {-webkit-font-smoothing: antialiased;}
body {
	font-family: 'Raleway', sans-serif;
	font-size: 14px;
	color: #000;
}

main {
	overflow-x:hidden;
}

a, a:hover, a:focus {
	text-decoration: none;
	outline: none;
}

input, input:focus,
button, button:focus {
	outline: none;
}

ul {
	margin:0px;
	padding:0px;
	list-style: none;
}

img {max-width: 100%;}
