.footer {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	min-height: 38px;
	background: $black;
	color: #fff;
	z-index: 999;
	.copyright {
		font-weight: 600;
		font-size: 10px;
		margin: 13px 0px 0px;
	}
	.social {
		margin-top: 9px;
		margin-bottom: 5px;
		li {
			display: inline-block;
			margin-left: 10px;
			a {
				font-size: 16px;
				color: #fff;
				&:hover {
					color: $red;
				}
			}
		}
	}
}

.footer2 {
	color: #fff;
	background: $black;
	padding-top: 40px;
	padding-bottom: 53px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		bottom: 15px;
		left: 0px;
		width: 100%;
		height: 1px;
		border-bottom: 0.5px solid rgba(255,255,255,0.5);
	}
	.logo {
		margin-bottom: 20px;
	}
	.text1 {
		margin-bottom: 15px;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.4;
	}
	.social {
		margin-top: 0px;
		margin-bottom: 20px;
		li {
			display: inline-block;
			margin-left: 10px;
			a {
				font-size: 16px;
				color: #fff;
				&:hover {
					color: $red;
				}
			}
		}
	}
	.info-block {
		display: flex;
		align-items: center;
		line-height: 1.3;
		@include md2down {
			margin-bottom: 15px;
			text-align: center;
			flex-direction: column;
		}
		i {
			display: flex;
			width: 35px;
			height: 35px;
			background: $red;
			border-radius: 50%;
			color: $black;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			margin-right: 8px;
		}
		strong {
			display: block;
		}
	}
}

.newsletter {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;
	position: relative;
	padding: 30px 0;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
	}
	.text1 {
		font-size: 14px;
		font-weight: 700;
		margin: 0;
	}
	.text2 {
		font-size: 26px;
		margin: 0;
		strong {
			font-weight: 700;
		}
	}
	.input {
		@include sm2down {
			margin-top: 15px;
		}
	}
	.submit {
		@include lg2up {
			min-width: 150px;
		}
	}
}