.header {
	padding-top: 10px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	@include md2up {
		padding-top: 30px;
	}
	@include lg2up {
		border-bottom: 0.5px solid rgba(255,255,255,0.5);
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 135px;
		background: linear-gradient(360deg, transparent, rgba(0,0,0,0.5));
	}
	.logo {
		img {
			@include lg2up {
				margin-bottom: 10px;
			}
		}
	}
}
.h-menu {
	margin-top: 54px;
	position: relative;
	> li {
		display: inline-block;
		margin: 0 20px;
		position: relative;
		padding-bottom: 22px;
		@include lg {
			padding-bottom: 10px;
		}
		@include md2down {
			padding-bottom: 0px;
		}
		&::before {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: rgba(255,255,255,0.8);
			opacity: 0;
			visibility: hidden;
		}
		&.last {
			&::before {
				display: none;
			}
		}
		a {
			display: block;
			color: #fff;
			font-weight: 500;
			font-size: 15px;
			transition: all ease 0.2s;
			white-space: nowrap;
			padding: 3px 0;
			&:hover {
				/*color: $red;*/
			}
			&.login {
				background: $red;
				padding: 3px 5px;
				&:hover {
					color: #fff;
				}
			}
		}
		&:hover, &.active {
			&::before {
				visibility: visible;
				opacity: 1;
			}
			.h-menu-sub1 {
				opacity: 1;
				visibility: visible;
				transition: all ease-in 0.2s;
			}
		}
	}
	&.h-menu2 {
		margin-top: 20px;
	}
	&.h-mobile {
		@include md2down {
			width: 100%;
			margin-top: 33px;
			border-top: 0.5px solid rgba(255,255,255,0.5);
		}
		li {
			@include md2down {
				display: block;
				margin: 0px;
			}
			a {
				> i {
					float: right;
					position: relative;
					top: 4px;
				}
				&[aria-expanded="true"] {
					> i {
						transform: rotate(180deg);
					}
				}
				
				@include md2down {
					padding: 5px 15px;
				}
				&:hover {
					@include md2down {
						background: #fff;
						color: $red;
					}
				}
			}
		}
		
		.card {
			padding: 0;
			background: none;
			border: none;
			a {
				padding-left: 30px;
			}
		}
	}
	.h-menu-sub1 {
		position: absolute;
		background-color: rgba(0,0,0,0.8);
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
		visibility: hidden;
		transition: all ease-out 0.1s;
		margin-top: 2px;
		li {
			display: block;
			border-bottom: 0.5px solid rgba(255,255,255,0.5);
			margin: 0 15px;
			a {
				display: block;
				padding: 5px 0;
				&:hover {
					color: $red;
				}
			}
		}
	}
}

.h-menu-sub2 {
	position: absolute;
	top: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.8);
	padding-top: 30px;
	margin-top: 2px;
	opacity: 0;
	visibility: hidden;
	transition: all ease-out 0.1s;
	@include md2down {
		display: none;
	}
	&.show {
		opacity: 1;
		visibility: visible;
		transition: all ease-in 0.2s;
	}
	.menu-thumb-block {
		padding-bottom: 58.77%;
		margin-bottom: 30px;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		.text {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: #fff;
			font-weight: 600;
			font-size: 16px;
			text-shadow: 2px 2px 3px rgba(0,0,0,0.78);
			background-color: rgba(0,0,0,0.5);
			transition: all ease-out 0.1s;
		}
		&:hover {
			.text {
				background-color: transparent;
				transition: all ease-in 0.2s;
			}
		}
	}
}

.menu-panel {
	background: $red;
	margin-top: 20px;
	position: relative;
	min-height: 33px;
	.btn-menu {
		position: absolute;
		right: 5px;

	}
}

.header2 {
	background-color: $red;
	height: 100px;
	padding-top: 40px;
	@include md2down {
		padding-top: 15px;
		height: 50px;
	}
	.link-back {
		color: #fff;
		font-size: 15px;
		font-weight: 500;
		text-transform: uppercase;
	}
}